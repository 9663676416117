/* eslint-disable no-undef */

import React, { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import * as serviceWorker from "./serviceWorker";
import Spinner from "./components/common/Spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

axios.defaults.baseURL = process.env.REACT_APP_API_URL || "/api";

const LazyApp = lazy(() => import("./App"));
const container = document.getElementById("root");

const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Suspense fallback={<Spinner />}>
    <LazyApp />
    <ToastContainer newestOnTop />
  </Suspense>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
